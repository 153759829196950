<template>
  <div>
    <!-- 模型加载loading -->
    <div class="back-ground-remover-wrap">
      <div class="header flex justify-start items-center">
        <back-btn @back="handleBack" />
      </div>
      <div class="imageFilter-container" :style="{ 'padding-top': minClient ? '20px' : '49px' }">
        <div class="canvas-show-container position-box" @mouseenter="handleMattingBoxMouseEnter"
          @mouseleave="handleMattingBoxMouseLeave">
          <image-board width="522px" v-if="modelInProgress === 'start'">
            <img crossOrigin :src="imageObj.url" />
          </image-board>
          <canvas id="previewer" v-else></canvas>
          <div class="buttons-container" v-if="showMattingEditBtns">
            <a-button type="primary" @click="downloadVideo()" :loading="loading">Download</a-button>
          </div>

          <div class="loading-mask-box" v-if="modelInProgress === 'start'"></div>
        </div>
        <div style="margin-top:18px;">
          <common-rate @changeRate="handleMattingChangeRate" :clearRate="clearRate" ref="rate" />
        </div>
        <div class="imageFilter-preview-container" v-if="modelInProgress" :style="{ 'top': minClient ? '20px' : '49px' }">
          <ul class="canvas-show-ul" :style="{
            pointerEvents: loading || !imageHandleComplete ? 'none' : 'all',
          }">
            <li class="canvas-show-li" v-for="(filter, index) in imageUrlConfig" :key="filter.id" v-show="index != 0"
              @click="handlePreviewClick(filter.id, index)">
              <div class="filter-image">
                <div class="canvas-show-hover" v-if="currentSelectItem === index"></div>
                <img :src="filter.imgUrl" width="76" :height="rightImageHeight" class="canvas-show-image"
                  :style="{ width: '76px', height: rightImageHeight + 'px' }" v-if="filter.imgUrl" />
                <div :style="{ width: '76px', height: '76px' }" width="76" :height="rightImageHeight"
                  class="canvas-show-image canvas-show-image-loading" v-else> </div>
              </div>
              <p class="filter-name">{{ filter.name }}</p>
            </li>
            <div class="placeholder-box"></div>
            <div class="placeholder-box"></div>
            <div class="placeholder-box"></div>
          </ul>
          <canvas id="render" v-show="false"></canvas>
        </div>
      </div>
    </div>
    <!-- <DownloadLoading :visible="modalVisible" @handleDownload="handleStopDownload" /> -->
  </div>
</template>

<script setup>
import { ref, onUnmounted, onMounted } from "vue";
import dynamicFilterPainter from "../../utils/webglRender/dynamicFilterPainter.js";
import { dynamicFilterConfig } from "../../utils/webglRender/videoFilter.js";
import CommonRate from "../common-rate.vue";
import BackBtn from "@/components/boolv-ui/back-btn";
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg/dist/ffmpeg.min.js";
import ImageBoard from "@/components/boolv-ui/image-board";
import { eventTracking, postCanvasToUrl } from "@/utils/eventTracking.js";
import { storeToRefs } from 'pinia'
import { useSideStore, useViewStore } from '@/store/index.js'
import { MessageLoading, destroy, MessageSuccess, MessageError } from "@/components/boolv-ui/Message/index.js";
import { i } from "mathjs";
const sideStore = useSideStore()
const viewStore = useViewStore()
const { minClient } = storeToRefs(sideStore)

let ffmpeg = createFFmpeg({ log: true });
var md5 = require("md5");

const uploadPlaceholder = ref("");
const currentImgUrl = ref("");
const currentMattingSelectRate = ref({});
const imageHandleComplete = ref(false);
const showMattingEditBtns = ref(false);
const canvasWidth = ref(522);
const canvasHeight = ref(522);
const imgMultiple = ref(1);
const modelInProgress = ref("start");
const currentSelectItem = ref(0);
const rate = ref(null);
const imageUrlConfig = ref(dynamicFilterConfig);
const canvasNum = ref(0);
const loading = ref(false);
const currentFilterId = ref(-1);
const clearRate = ref(false);
const rightImageHeight = ref(76);
const mediaRecord = ref(null);
const isPreview = ref(false)

const emits = defineEmits(["back", "processed"]);
const props = defineProps({
  imageObj: Object,
});

onMounted(() => {
  MessageLoading("Processing, it will take a while", 0, "", null, false);
  // viewStore.setMinViewWidth('1400px')
  cancelAnimationFrame(window.myReq);
  draw(props.imageObj.url);
});
onUnmounted(() => {
  destroy()
})
const renderLiCanvas = (dynamicFilterConfig, url, index) => {
  if (!dynamicFilterConfig || !dynamicFilterConfig.length) {
    return;
  }

  //在渲染右侧 预览的时候去分批次加载所有的滤镜，加载完毕后截图，删除这个webgl上下文
  const filter = dynamicFilterConfig[index];
  dynamicFilterPainter(
    `#${filter.id}`,
    76,
    76,
    filter.vertexShader,
    filter.fragmentShader,
    url,
    false,
    webglLoadDone,
    true,
    false
  );
};

const draw = (url) => {
  currentImgUrl.value = url;
  // drowAfterLoadImage(url);
  renderLiCanvas(dynamicFilterConfig, url, 0);
};

const handlePreviewClick = async (id, index) => {
  // clearMainCanvas();
  isPreview.value = true
  currentSelectItem.value = index;
  eventTracking('booltool_page_edit', { is_batch: false, edit_type: 'animation', asset_id: `asset${currentSelectItem.value}` });
  await drowAfterLoadImage(currentImgUrl.value, index + 1, false, false);
};

function record (canvas, time) {
  var stream = canvas.captureStream(25 /*fps*/);
  let params = {};

  if (MediaRecorder.isTypeSupported("video/webm; codecs=h264")) {
    //chrome
    params = {
      mimeType: "video/webm; codecs=h264",
    };
  } else if (MediaRecorder.isTypeSupported("video/mp4;  codecs=avc1")) {
    //safari
    params = {
      mimeType: "video/mp4;  codecs=avc1",
    };
  }

  var recordedChunks = [];
  //通过使用MediaRecorder.isTypeSupported() 判断当前浏览器使用不同的编码格式
  //safari video/mp4;  codecs=avc1
  //chrome video/webm; codecs=h264
  const mediaRecorder = new MediaRecorder(stream, params);
  mediaRecord.value = mediaRecorder;
  mediaRecorder.start(time || 5500);
  mediaRecorder.ondataavailable = function (event) {
    recordedChunks.push(event.data);
    // if (mediaRecorder.state === "recording") {
    //   mediaRecorder.stop();
    // }
  };

  mediaRecorder.onstop = async function () {
    var blob = new Blob(recordedChunks, { type: "video/webm;codecs=h264" });
    if (!ffmpeg.isLoaded()) {
      await ffmpeg.load();
    }

    ffmpeg.FS("writeFile", "test.webm", await fetchFile(blob));
    await ffmpeg.run("-i", "test.webm", "-c:v", "copy", "-an", "test.mp4");
    const data = ffmpeg.FS("readFile", "test.mp4");
    const dataUrl = URL.createObjectURL(
      new Blob([data.buffer], { type: "video/mp4" })
    );
    loading.value = false;
    var link = document.createElement("a");
    link.href = dataUrl;
    link.download = `photo-animation-${md5(Date.now())}.mp4`;
    link.click();

    eventTracking("booltool_page_download", { tool_name: "animation", is_batch: false, is_success: true, asset_id: `asset${currentFilterId.value || 0}` })
  };

  setTimeout(() => {
    mediaRecorder.stop();
    mediaRecorder.value = null;
  }, 6000);
}

function downloadVideo () {
  loading.value = true;
  const canvas = document.getElementById("previewer");
  record(canvas, 5500);
}

const handleMattingChangeRate = (item) => {
  const emoji = ['star_struck', 'face_blowing_a_kiss', 'thinking_face', 'face_vomiting'];
  currentMattingSelectRate.value = item;
  eventTracking('booltool_page_feedback', { tool_name: 'animation', emoji_str: emoji[item.id - 1] });
};

const handleMattingBoxMouseEnter = () => {
  showMattingEditBtns.value = true;
};

const handleMattingBoxMouseLeave = () => {
  showMattingEditBtns.value = false;
};

function timeout (ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const getImageFromCanvas = async (
  filterId,
  gl,
  texture,
  buffer,
  indicesBuffer
) => {
  const canvas = document.getElementById("render");
  await timeout(50);
  const dataURL = canvas.toDataURL();
  gl.deleteTexture(texture);
  gl.deleteBuffer(buffer);
  gl.deleteBuffer(indicesBuffer);
  // gl.getExtension('WEBGL_lose_context').loseContext();
  imageUrlConfig.value.forEach((filter) => {
    if (filterId == filter.id) {
      filter.imgUrl = dataURL;
      // document.getElementById(filterId).remove();
    }
  });
};

const webglLoadDone = async (id, gl, texture, buffer, indicesBuffer) => {
  const filterId = id.replace("#", "");
  //截图 删除上下文
  measureImageSize(currentImgUrl.value)
  await getImageFromCanvas(filterId, gl, texture);
  if (canvasNum.value == dynamicFilterConfig.length - 1) {
    emits("processed")
    drowAfterLoadImage(currentImgUrl.value);
    imageHandleComplete.value = true;
    modelInProgress.value = "done";
    uploadPlaceholder.value = "Change an image here";
    return;
  }
  const index = canvasNum.value;
  setTimeout(() => {
    renderLiCanvas(dynamicFilterConfig, currentImgUrl.value, index + 1);
  }, 0);

  canvasNum.value += 1;
};

const measureImageSize = (currentImgUrl) => {
  let img = new Image();
  img.src = currentImgUrl
  img.crossOrigin = "anonymous";
  img.onload = function () {
    const { imgWidth, imgHeight } = showImage(img);
    canvasWidth.value = imgWidth;
    canvasHeight.value = imgHeight;
    rightImageHeight.value = (imgHeight * 76) / imgWidth;
  };
}

const drowAfterLoadImage = (url, index, isClear, isRenderList) => {
  let img = new Image();
  const filterIndex = index ? index - 1 : 0;
  const clear = isClear === false ? false : true;
  img.onload = function () {
    const { imgWidth, imgHeight } = showImage(img);
    canvasWidth.value = imgWidth;
    canvasHeight.value = imgHeight;
    currentImgUrl.value = url;
    rightImageHeight.value = (imgHeight * 76) / imgWidth;
    currentFilterId.value = index;
    dynamicFilterPainter(
      "#previewer",
      imgWidth,
      imgHeight,
      dynamicFilterConfig[filterIndex].vertexShader,
      dynamicFilterConfig[filterIndex].fragmentShader,
      url,
      clear,
      webglLoadDone,
      false,
      false
    );
  };
  img.src = url;

  if (isPreview.value) {
    return
  }
  MessageSuccess("Processing completed");
  setTimeout(() => {
    destroy()
  }, 1000)
};

// 图片缩放
const showImage = (image) => {
  if (image.width > 522 || image.height > 522) {
    // 长 > 宽
    if (522 / image.width > 522 / image.height) {
      if (522 / image.width < 1 && 522 / image.height < 1) {
        imgMultiple.value = 522 / image.height;
      } else if (522 / image.width > 1 && 522 / image.height < 1) {
        imgMultiple.value = 522 / image.height;
      }
    } else if (522 / image.width < 522 / image.height) {
      // 宽 > 长
      if (522 / image.width < 1 && 522 / image.height < 1) {
        imgMultiple.value = 522 / image.width;
      } else if (522 / image.width < 1 && 522 / image.height > 1) {
        imgMultiple.value = 522 / image.width;
      }
    } else {
      imgMultiple.value = 522 / image.width;
    }
  } else if (image.width < 522 && image.height < 522) {
    if (522 / image.width > 522 / image.height) {
      imgMultiple.value = 522 / image.height;
    } else if (522 / image.width < 522 / image.height) {
      imgMultiple.value = 522 / image.width;
    } else {
      imgMultiple.value = 1;
    }
  }
  const imgWidth = imgMultiple.value * image.width;
  const imgHeight = imgMultiple.value * image.height;
  const imgX = (522 - imgWidth) / 2;
  const imgY = (522 - imgHeight) / 2;

  return {
    imgX,
    imgY,
    imgWidth,
    imgHeight,
  };
};

const clearCanvas = () => {
  modelInProgress.value = null
  imageUrlConfig.value = dynamicFilterConfig

}
const handleBack = () => {
  clearCanvas()
  emits("back");
};
</script>

<style lang="less" scoped>
.header {
  width: 100%;
  padding-left: 36px;
  margin-top: 25px;
}

.backGroundRemover-container {
  width: 100%;
  height: 452px;
  margin-top: 82px;
  display: flex;
  justify-content: center;
}

.back-ground-remover-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 1400px;
}

.container {
  margin-right: 118px;
}

.tool-title {
  font-family: 'Inter', Arial;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  color: #000000;
  margin-bottom: 52px;
}

.imageFilter-container {
  width: 100%;
  position: relative;
}

.common-upload-box {
  width: 604px;
  height: 92px;
  border: 2px dashed #878787;
  border-radius: 6px;
}

.canvas-show-hover {
  position: absolute;
  width: 76px;
  height: 76px;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.45);
}

.canvas-show-container {
  margin: 0 auto;
  text-align: center;
  background: #f7f7f7;
  width: 522px;
  height: 522px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageFilter-preview-container {
  position: absolute;
  right: 100px;
}

.canvas-show-ul {
  list-style-type: none;
  width: 271px;
  height: 522px;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.canvas-show-ul::-webkit-scrollbar {
  background-color: transparent;
  /* or add it to the track */
  width: 7px;
}

.canvas-show-ul::-webkit-scrollbar-thumb {
  background: rgba(31, 35, 41, 0.3);
  border-radius: 3.5px;
  width: 7px !important;
  height: 135px !important;
}

.canvas-show-ul::-webkit-scrollbar-track {
  background: transparent;
}

.canvas-show-li {
  width: 76px;
  height: 76px;
  background: #ededed;
  border-radius: 6px;
  margin-right: 12px;
  margin-bottom: 42px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  .filter-image {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    border-radius: 6px;
    align-items: center;
  }

  img {
    height: inherit;
  }

  .filter-name {
    position: absolute;
    bottom: -22px;
    width: 100%;
    overflow: hidden; //超出一行文字自动隐藏 
    text-overflow: ellipsis; //文字隐藏后添加省略号
    white-space: nowrap; //强制不换行

    color: #1C1B1E;
    font-size: 12px;
    line-height: 14px;
  }
}

.canvas-show-li:nth-last-of-type(-n + 3) {
  margin-bottom: 0;
}

.canvas-show-image {
  -webkit-user-drag: none;
}

.canvas-show-li:hover {
  cursor: pointer;
}

.canvas-show-li canvas {
  border-radius: 4px;
}

.placeholder-box {
  width: 76px;
  display: inline-block;
  height: 1px;
  margin-right: 12px;
}

.position-box {
  position: relative;

  .buttons-container {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 80px 14px 80px;
    display: flex;
    justify-content: center;

    .ant-btn-primary {
      border-color: #875eff;
    }

    .ant-btn {
      width: 120px;
      height: 36px;
      border-radius: 200px;
      color: #fff;
      border-color: #875eff;
    }

    .ant-btn-primary:hover,
    .ant-btn-primary:focus {
      border-color: #875eff;
    }

    .ant-btn-default {
      background: rgba(0, 0, 0, 0.8);
      opacity: 0.8;
    }

    .ant-btn-primary {
      opacity: 1;
      background: linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%);

      &:hover {
        background: linear-gradient(289deg, #7646ff 18.5%, #9c7aff 99.95%);
      }
    }
  }

  @keyframes fadenum {
    0% {
      opacity: 0;
      transform: scaleX(0.5);
    }

    100% {
      opacity: 1;
      transform: scaleX(1);
    }
  }
}

.loading-mask-box {
  width: 100%;
  height: 100%;
  background-color: #F7F7F7;
  opacity: 0.5;
  position: absolute;
  left: 0;
  top: 0;
  text-align: right;
}

.canvas-show-image-loading {
  background-color: #f7f7f7;
}
</style>
