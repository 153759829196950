import { e } from "mathjs";
import {
  getCanvas,
  resizeCanvas,
  getContext,
  createSimpleProgramFromScript,
  loadTexture0,
} from "./webgl-helper.js";
//变量1 canvas id :string
//变量2 要绘制的canvas的宽高，受图片影响 :int
//变量3 顶点着色器代码 :string
//变量4 片元着色器代码 :string
//变量5 要渲染的图片 :url
//变量6 lut图      :url
//变量7 是否需要清空画布 :boolean
//变量8 是否是右侧的缩略图 :boolean
export default function dynamicFilterPainter (
  id,
  width,
  height,
  vertexShader,
  fragmentShader,
  img,
  isClear,
  cb,
  isPreviewCanvas,
  stop
) {
  const canvasId = id == "#previewer" ? id : "#render";
  let canvas = getCanvas(canvasId);
  //设置canvas尺寸为满屏
  //获取绘图上下文
  let gl = getContext(canvas);

  if (isPreviewCanvas) {
    canvas.style.width = width + "px";
    canvas.style.height = height + "px";
  } else {
    resizeCanvas(canvas, width, height);
  }
  if (isClear) {
    gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);
    // Set the clear color to darkish green.
    gl.clearColor(1, 1, 1, 1);
    // Clear the context with the newly set color. This is
    // the function call that actually does the drawing.
    gl.clear(gl.COLOR_BUFFER_BIT);
  }

  //创建着色器程序
  let program = createSimpleProgramFromScript(gl, vertexShader, fragmentShader);
  //使用该着色器程序
  gl.useProgram(program);
  let positions = [-1, -1, 0, 0, -1, 1, 0, 1, 1, 1, 1, 1, 1, -1, 1, 0];
  // 找到着色器中的全局变量 u_Texture;
  var texture1 = gl.getUniformLocation(program, "ourTexture");
  var texture2 = gl.getUniformLocation(program, "ourTexture1");
  var u_Screen_Size = gl.getUniformLocation(program, "u_Screen_Size");
  gl.uniform2f(u_Screen_Size, canvas.width, canvas.height);
  var a_Position = gl.getAttribLocation(program, "a_Position");
  var a_TexCoord = gl.getAttribLocation(program, "a_TexCoord");

  gl.enableVertexAttribArray(a_Position);
  gl.enableVertexAttribArray(a_TexCoord);
  // 创建缓冲区
  var buffer = gl.createBuffer();
  // 绑定缓冲区为当前缓冲
  gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
  // 设置 a_Position 属性从缓冲区读取数据方式
  gl.vertexAttribPointer(a_Position, 2, gl.FLOAT, false, 16, 0);
  // 设置 a_TexCoord 属性从缓冲区读取数据方式
  gl.vertexAttribPointer(a_TexCoord, 2, gl.FLOAT, false, 16, 8);
  // 向缓冲区传递数据
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW);
  //定义绘制索引数组
  let indices = [0, 1, 2, 0, 2, 3];
  //创建索引缓冲区
  let indicesBuffer = gl.createBuffer();
  //绑定索引缓冲区
  gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indicesBuffer);
  //向索引缓冲区传递索引数据
  gl.bufferData(
    gl.ELEMENT_ARRAY_BUFFER,
    new Uint16Array(indices),
    gl.STATIC_DRAW
  );
  //设置清屏颜色为黑色。
  gl.clearColor(1, 1, 1, 1);
  let time = 0;
  let i = 0;
  let num1 = [];
  let num2 = [];
  while (i < 50) {
    num1.push(Math.random());
    num2.push(Math.random());
    i++;
  }

  let myReq;

  function render () {
    gl.clear(gl.COLOR_BUFFER_BIT);
    if (positions.length <= 0) {
      return;
    }
    let offset = Math.sin(time) / 2.0 + 0.5;
    gl.uniform2f(u_Screen_Size, canvas.width, canvas.height);
    gl.uniform1f(gl.getUniformLocation(program, "u_offset"), offset);
    gl.uniform1f(gl.getUniformLocation(program, "u_Offset"), time);
    gl.uniform1f(gl.getUniformLocation(program, "Time"), time);
    gl.uniform1f(gl.getUniformLocation(program, "nTime"), time % 1.5);
    gl.uniform1f(gl.getUniformLocation(program, "uTime"), (time % 2) + 0.7);
    gl.uniform2f(
      gl.getUniformLocation(program, "u_TouchXY"),
      num1[Math.floor(time)],
      num2[Math.floor(time)]
    );
    gl.drawElements(gl.TRIANGLES, indices.length, gl.UNSIGNED_SHORT, 0);
    time = time + 1.0 / 60.0;
    if (!stop) {
      myReq = window.requestAnimationFrame(render);
    }
    window.myReq = myReq;
  }

  loadTexture0(gl, true, img, texture1, function (gl, texture) {

    render();

    if (isPreviewCanvas) {
      cb(id, gl, texture, buffer, indicesBuffer);
    }
  });
}
